import ReactDOM from "react-dom";
import React from 'react'
import "./index.css"
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './pages/Home'
import NoPage from './pages/NoPage'
import Layout from './pages/Layout'
import Portfolio from './pages/Portfolio'
import Farmable from "./pages/Farmable";


export default function App() {
  return (
    
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="*" element={<NoPage />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/portfolio/farmable" element={<Farmable />} />
          </Route>
        </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);