import React from "react";
import Post from "../components/portfolio/Post";

{/* <Post  image={""} number={} title={""} text={""} link={''}  /> */}

const Portfolio = () => {

	return (
		<div>
			<div className="flex justify-center text-white font-bold text-[30px] pt-[40px]">
				<h1>Our Portfolio</h1>
			</div>
				<Post  image={"https://cdn.discordapp.com/attachments/751907902532616257/1092069513291366492/CH-Icon-2.png"} number={1} title={"Lorem Ipsum"} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."} link={'/portfolio/charged'}  />
				<Post  image={"https://cdn.discordapp.com/attachments/751907902532616257/1092069513291366492/CH-Icon-2.png"} number={2} title={"Lorem Ipsum"} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."} link={'/portfolio/charged'}  />
				<Post  image={"https://cdn.discordapp.com/attachments/751907902532616257/1092069513291366492/CH-Icon-2.png"} number={3} title={"Lorem Ipsum"} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."} link={'/portfolio/charged'}  />
				<Post  image={"https://cdn.discordapp.com/attachments/751907902532616257/1092069513291366492/CH-Icon-2.png"} number={4} title={"Lorem Ipsum"} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."} link={'/portfolio/charged'}  />
				<Post  image={"https://cdn.discordapp.com/attachments/751907902532616257/1092069513291366492/CH-Icon-2.png"} number={5} title={"Lorem Ipsum"} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."} link={'/portfolio/charged'}  />
				<Post  image={"https://cdn.discordapp.com/attachments/751907902532616257/1092069513291366492/CH-Icon-2.png"} number={6} title={"Lorem Ipsum"} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."} link={'/portfolio/charged'}  />
				<Post  image={"https://cdn.discordapp.com/attachments/751907902532616257/1092069513291366492/CH-Icon-2.png"} number={7} title={"Lorem Ipsum"} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."} link={'/portfolio/charged'}  />
				<Post  image={"https://cdn.discordapp.com/attachments/751907902532616257/1092069513291366492/CH-Icon-2.png"} number={8} title={"Lorem Ipsum"} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."} link={'/portfolio/charged'}  />
				<Post  image={"https://cdn.discordapp.com/attachments/751907902532616257/1092069513291366492/CH-Icon-2.png"} number={9} title={"Lorem Ipsum"} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."} link={'/portfolio/charged'}  />
				<Post  image={"https://cdn.discordapp.com/attachments/751907902532616257/1092069513291366492/CH-Icon-2.png"} number={10} title={"Lorem Ipsum"} text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."} link={'/portfolio/charged'}  />		</div>
	)

}

export default Portfolio

