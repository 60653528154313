import React from 'react'
import { ReactComponent as Logo } from './heroimage.svg';

function Hero() {
	return (
		<div>
			<div className='hidden xl:block'>
				<div className='flex justify-center items-centre font-jose font-semibold'> 
						<div class="flex justify-center items-center text-centre  md:pt-[250px]">
							<div className='grid grid-cols-2'>
								<Logo className='hidden md:block w-[450px h-[350px] '/>
								<div className='pt-[80px]'>
									<h1 className=' font-extrabold text-[50px] text-white'>Charged<span className='text-[#FFD700]'>.gg</span> </h1>
									<p className='text-left text-clip overflow-hidden pb-5 text-white'>
										Charged with <span className='text-[#FFD700]'>creativity</span> and <span className='text-[#FFD700]'>excellence</span> - Crafting  bespoke <br/><span className='text-[#FFD700]'>software solutions</span>  that drive your business <span className='text-[#FFD700]'>forward</span>. 
									</p>
									<a href="/contact" type="button" className="bg-[#011A27] text-white w-[130px] h-[51px] transition-all  items-center group duration-300 hover:scale-105 space-x-2.5  appearance-none inline-flex hover:shadow-2xl py-4 px-5 rounded-[12px] cursor-pointer"><span class="w-full font-semibold text-base">Contact Us</span></a>
								</div>
							</div>
						</div>
				</div>
			</div>

			<div className='xl:hidden'>
				<div className='flex h-screen justify-center'>
					<div className='pt-[280px]'>
						<h1 className=' font-extrabold md:text-[70px] text-[50px] text-white'>Charged<span className='text-[#FFD700]'>.gg</span> </h1>
						<p className='text-left text-clip overflow-hidden text-[13px] md:text-[18px] pb-5 text-white'>
							Charged with <span className='text-[#FFD700]'>creativity</span> and <span className='text-[#FFD700]'>excellence</span> - Crafting  bespoke <br/><span className='text-[#FFD700]'>software solutions</span>  that drive your business <span className='text-[#FFD700]'>forward</span>. 
						</p>
						<a href="/contact" type="button" className="bg-[#011A27] text-white w-[100px] h-[40px]  items-center  inline-flex  py-4 px-5 rounded-[12px] cursor-pointer"><span class="w-full font-semibold text-[12px]">Contact Us</span></a>
					</div>
				</div>
			</div>
		</div>


	)
}

export default Hero