import React from 'react'

function NoPage() {
  return (
	<div>
		<div className='hidden md:block'>
			<div className="h-screen flex justify-center items-center text-centre  font-jose font-semibold">
				<h1 className='text-[40px] text-white'><span className='text-[#FFD700]'>404</span> | Page not found</h1>
			</div>
		</div>

		<div className='md:hidden'>
		<div className="h-screen flex justify-center items-center text-centre  font-jose font-semibold">
				<h1 className='text-[30px] text-white'><span className='text-[#FFD700]'>404</span> | Page not found</h1>
			</div>
		</div>
	</div>

  );
}

export default NoPage;
