import React from "react";
import Navbar from "../components/navbar/Navbar";

const Layout = () => {
  return (
    <>
	<Navbar />
    </>
  );
};

export default Layout;