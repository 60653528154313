import React from "react";

const Post = ({ number, image, title, text, link }) => {
  return (
    <div>
      <div className="font-jose font-semibold text-black hidden lg:block">
        <div className="flex justify-center p-[30px]">
          <div className="transition-all duration-300 hover:scale-105 space-x-2.5 hover:shadow-2xl  appearance-none bg-[#07324B]  w-[800px] h-[350px] rounded-[20px] drop-shadow-lg  ">
            <div className="grid grid-cols-2">
              <div className="flex h-[350px] justify-center items-center">
                <div className="bg-[#093E5D]  w-[270px] h-[270px] rounded-[20px] drop-shadow-lg flex justify-center items-center">
                  <img
                    src={image}
                    className="h-[250px] w-[250px] rounded-[12px]"
					alt="portfolio"
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-row gap-[25px] text-white pt-[40px]">
                  <div className="bg-[#093E5D]  w-[50px] h-[50px] rounded-[20px] drop-shadow-lg flex items-center justify-center">
                    <h1 className=" text-[25px] text-[#FFD700]">#{number}</h1>
                  </div>
                  <div className="bg-[#093E5D]  w-[140px] h-[50px] rounded-[20px] drop-shadow-lg flex items-center justify-center  ">
                    <h1 className=" text-[15px]">{title}</h1>
                  </div>

                  <a
                    type="button"
                    className="flex justify-center items-center w-[100px] h-[50px] bg-[#093E5D] rounded-[20px] transition-all duration-300 hover:scale-105 space-x-2.5 hover:shadow-2xl  appearance-none drop-shadow-lg text-[#FFD700] border-white "
                    href={link}
                  >
                    More
                  </a>
                </div>
                <div className="text-white ">
                  <div className="pt-[20px]">
                    <div className="bg-[#093E5D]  w-[340px] h-[210px] rounded-[20px] drop-shadow-lg flex-col flex items-center justify-center ">
                      <p className=" text-[15px] pl-4 pr-4 font-medium">
                        {text}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Stuff */}

      <div className="font-jose font-semibold text-black lg:hidden">
        <div className="flex justify-center p-[50px]">
          <div className="transition-all duration-300 hover:scale-105 space-x-2.5 hover:shadow-2xl  appearance-none bg-[#07324B]  w-[300px] h-[650px] rounded-[20px] drop-shadow-lg  ">
            <div className="flex gap-[50px] text-white pt-5 pl-7">
              <div className="bg-[#093E5D]  w-[50px] h-[50px] rounded-[20px] drop-shadow-lg flex items-center justify-center">
                <h1 className="text-[25px] text-[#FFD700] ">#{number}</h1>
              </div>
              <div className="bg-[#093E5D]  w-[140px] h-[50px] rounded-[20px] drop-shadow-lg flex items-center justify-center ">
                <h1 className=" text-[15px]">{title}</h1>
              </div>
            </div>
            <div className="flex justify-center pt-5 pr-3">
              <div className="bg-[#093E5D]  w-[240px] h-[240px] rounded-[20px] drop-shadow-lg flex justify-center items-center">
                <img
                  src={image}
                  className="h-[250px] w-[250px] rounded-[12px]"
				  alt="portfolio"
                />
              </div>
            </div>
            <div className="flex justify-center font-jose text-white pt-5 pr-3">
              <div className="bg-[#093E5D]  w-[240px] h-[210px] rounded-[20px] drop-shadow-lg flex-col flex items-center justify-center ">
                <p className=" text-[15px] pl-4 pr-4 font-medium">{text}</p>
              </div>
            </div>
            <div className="text-white flex justify-center items-center pt-5 pr-3">
              <a
                type="button"
                className="flex justify-center items-center w-[100px] h-[50px] bg-[#093E5D] rounded-[20px] transition-all duration-300 hover:scale-105 space-x-2.5 hover:shadow-2xl  appearance-none drop-shadow-lg text-[#FFD700]"
                href={link}
              >
                More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
