import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { Briefcase, Users, ShoppingCart, Mail } from "lucide-react";

import { BiMenu, BiMenuAltRight } from "react-icons/bi";
import Bottom from "./Bottom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <nav className="font-semibold bg-[#011A27]  text-white ">
        <div className="max-w-7xl mx-auto px-4 ">
          <div className="flex items-center justify-between h-16">
            <div className="flex justify-center font-bold  rounded-[7px] box border-4 border-transparent hover:bg-black/5 text-[22px] ">
              <Link to="/">
                Charged<span className="text-[#FFD700]">.gg</span>
              </Link>
            </div>

            <div className="hidden md:block">
              <ul className="grid gap-[25px] grid-cols-4 text-black  ">
                <div className="flex justify-center  rounded-[7px] box border-4 border-transparent hover:bg-black/20 pt-1 pb-1">
                  <Briefcase color="#FFD700" className="cursor-pointer" />
                  <li className="pl-3 text-white">
                    <Link to="/portfolio">Portfolio</Link>
                  </li>
                </div>
                <div className="flex justify-center  rounded-[7px] box border-4 border-transparent hover:bg-black/20 pt-1 pb-1">
                  <Users color="#FFD700" className="cursor-pointer" />
                  <li className="pl-3 text-white">
                    <Link to="/team">Team</Link>
                  </li>
                </div>
                <div className="flex justify-center  rounded-[7px] box border-4 border-transparent hover:bg-black/20 pt-1 pb-1">
                  <ShoppingCart color="#FFD700" className="cursor-pointer" />
                  <li className="pl-3 text-white">
                    <Link to="/store">Store</Link>
                  </li>
                </div>
                <div className="flex justify-center  rounded-[7px] box border-4 border-transparent hover:bg-black/20 pt-1 pb-1">
                  <Mail color="#FFD700" className="cursor-pointer" />
                  <li className="pl-3 text-white">
                    <Link to="/contact">Contact</Link>
                  </li>
                </div>
              </ul>
            </div>

            <div className="-mr-2 flex md:hidden">
              <button
                type="button"
                onClick={toggleMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              >
                {isOpen ? (
                  <BiMenuAltRight color="#FFD700" className="block h-6 w-6" />
                ) : (
                  <BiMenu color="#FFD700" className="block h-6 w-6" />
                )}
              </button>
            </div>
          </div>
        </div>

        <div className={`${isOpen ? "block" : "hidden"} md:hidden`}>
          <ul className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <div className="flex  rounded-[7px] box border-4 border-transparent hover:bg-black/5 pt-1 pb-1">
              <Briefcase color="#FFD700" className="cursor-pointer" />
              <li className="pl-3 text-white">
                <Link to="/portfolio">Portfolio</Link>
              </li>
            </div>
            <div className="flex  rounded-[7px] box border-4 border-transparent hover:bg-black/5 pt-1 pb-1">
              <Users color="#FFD700" className="cursor-pointer" />
              <li className="pl-3 text-white">
                <Link to="/team">Team</Link>
              </li>
            </div>
            <div className="flex  rounded-[7px] box border-4 border-transparent hover:bg-black/5 pt-1 pb-1">
              <ShoppingCart color="#FFD700" className="cursor-pointer" />
              <li className="pl-3 text-white">
                <Link to="/store">Store</Link>
              </li>
            </div>
            <div className="flex  rounded-[7px] box border-4 border-transparent hover:bg-black/5 pt-1 pb-1">
              <Mail color="#FFD700" className="cursor-pointer" />
              <li className="pl-3 text-white ">
                <Link to="/contact">Contact</Link>
              </li>
            </div>
          </ul>
        </div>
      </nav>

      <Outlet />
    </div>
  );
};

export default Navbar;
