import React from 'react';
import { Heart  } from 'lucide-react'



const Bottom = () => {


  return (
   <div className='flex justify-center p-4'>
		<h1 className='text-white font-jose font-semibold'>© 2023 Charged. All Rights Reserved.</h1>
   </div>
  )
}

export default Bottom